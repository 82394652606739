<template>
  <v-app-bar color="primary" dark app>
    <v-app-bar-nav-icon class="pa-2" @click="appBarLogoClick">
      <v-img src="@/assets/img/logo-inverse.png" width="24" alt="Ads攻略 アイコン"></v-img>
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
  import { globalMixins } from '@/mixins';

  export default {
    name: 'AppHeader',
    mixins: [
      globalMixins
    ],
    data: () => ({
      title: null,
      pageItems: [
        {
          path: "/",
          icon: "mdi-home-variant-outline",
          title: "Home",
        },
        {
          path: "/about",
          icon: "mdi-information-outline",
          title: "About",
        },
        {
          path: "/contents",
          icon: "mdi-apps",
          title: "Contents",
        },
      ],
    }),
    watch: {
      '$route': function( to, from ){
        if( to.path != from.path ){
          this.title = this.getPageTitle( to.meta.title )
        }
      },
    },
    mounted(){
      this.title = this.getPageTitle( this.$router.currentRoute.meta.title )
    },
    methods: {
      onPageItemClick: function( path ){
        if( path != this.$router.currentRoute.path ){
          this.$router.push( path )
        }
      },
      appBarLogoClick: function(){
        this.$router.push( "/" )
      }
    },
  }
</script>
