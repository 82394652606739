import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Dictionary from '../views/Dictionary.vue'
import Beginner from '../views/Beginner.vue'
import Arts from '../views/Arts.vue'
import NotFound from '../views/NotFound.vue'
import { setTitle, setDescription, setKeywords } from '@/mixins';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: "このサイトについて"
    }
  },
  {
    path: '/dictionary',
    name: 'Dictionary',
    component: Dictionary,
    meta: {
      title: "Ads ワード辞典"
    }
  },
  {
    path: '/beginner',
    name: 'Beginner',
    component: Beginner,
    meta: {
      title: "初めてのAds攻略"
    }
  },
  {
    path: '/arts',
    name: 'Arts',
    component: Arts,
    meta: {
      title: "Ads攻略のコツ"
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: "ページが見つかりません"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior( to, from, savedPosition ){ // eslint-disable-line no-unused-vars
    return { x: 0, y: 0 }
  }
})

router.beforeEach( ( to, from, next ) => {
  setTitle( to.meta.title )
  setDescription( to.meta.description )
  setKeywords( to.meta.keywords )
  next()
})

export default router
