export const getPageTitle = pathPageTitle => {
  const defaultPageTitle = 'Ads攻略'
  const pageTitle = !pathPageTitle ? defaultPageTitle : pathPageTitle
  return pageTitle
}

export const getBreadCrumbs = ( routes, breadCrumbsTree ) => {
  return breadCrumbsTree.map( function( item, index ){
    const route = routes.filter( it => { return it.name == item } ).shift()
    return {
      text: ( index == 0 ) ? 'ホーム' : route.meta.title,
      disabled: ( index == breadCrumbsTree.length - 1 ),
      href: route.path
    }
  } )
}

export const getCopyRights = () => {
  const copyRights = "\u00A9" + "2008" + "\u2013" + ( new Date() ).getFullYear() + " Hi.N.E All Rights Reserved."
  return copyRights
}

export const setTitle = pathTitle => {
  const siteTitle = 'Ads攻略'
  const pageTitle = !pathTitle ? siteTitle : pathTitle + ' - ' + siteTitle
  return ( window.document.title = pageTitle )
}

export const setDescription = pathDescription => {
  const defaultDescription = 'Web広告の初心者がアドセンスやアドモブを使いこなすにあたってのコツやAds関連のワード辞典などをまとめています。'
  const description = pathDescription ? pathDescription : defaultDescription
  document
    .querySelector( "meta[name='description']" )
    .setAttribute( 'content', description )
}

export const setKeywords = pathKeywords => {
  const defaultKeywords = 'Google AdSense,Google AdMob,アドセンス,アドモブ,アフィリエイト,広告,収入,初心者,攻略,辞典,Ads'
  const keywords = pathKeywords ? pathKeywords : defaultKeywords
  document
    .querySelector( "meta[name='keywords']" )
    .setAttribute( 'content', keywords )
}

export const globalMixins = {
  methods: {
    getPageTitle,
    getBreadCrumbs,
    getCopyRights,
    setTitle,
    setDescription,
    setKeywords
  }
}
